//@ts-nocheck
import React, { useState, useEffect, useCallback } from "react";
import Bookmark from "./bookmark";
import man from "../../../assets/images/dashboard/profile.png";
import {
    AlignCenter,
    FileText,
    Activity,
    User,
    Clipboard,
    Anchor,
    Settings,
    LogOut,
    ThumbsUp,
    MessageCircle,
    MessageSquare,
    Maximize,
    Search,
    MoreHorizontal,
    CreditCard,
    Folder,
} from "react-feather";
import { Row, Col, Form, FormGroup, Button, Jumbotron } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import "./customStyles.css"

const HeaderSM = (props) => {
    // eslint-disable-next-line
    const history = useHistory();
    const [mainmenu, setMainMenu] = useState(props.menuItems);
    const [searchValue, setsearchValue] = useState("");
    const [navmenu, setNavmenu] = useState(false);
    const [searchinput, setSearchinput] = useState(false);
    const [spinner, setspinner] = useState(false);
    // eslint-disable-next-line
    const [searchResult, setSearchResult] = useState(false);
    // eslint-disable-next-line
    const [searchResultEmpty, setSearchResultEmpty] = useState(false);

    const [sidebar, setSidebar] = useState("iconsidebar-menu");
    const [rightSidebar, setRightSidebar] = useState(true);
    const [cName,setCname] =useState("");

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setsearchValue("");
        }
    }, []);

    const onMyAssetClick = () => {
        history?.push(`/my-assets`);
    };

    const onMyWalletClick = () => {
        history?.push(`/my-wallet`);
    };

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const onLogoutClick = () => {
        props?.onLogoutClick && props?.onLogoutClick();
    };

    useEffect(() => {
        setSidebar("iconbar-second-close");
        document
            .querySelector(".iconsidebar-menu")
            .classList.add("iconbar-second-close");
        document
            .querySelector(".iconsidebar-menu")
            .classList.remove("iconbar-mainmenu-close");
    }, []);

    // document.body.addEventListener('click', onBodyClick, true);

    // function onBodyClick(){
    //   console.log(`on body clicked`);
    // }

    // document.body.addEventListener('click', function () {

    //   setSidebar("iconsidebar-menu");
    //     document
    //       .querySelector(".iconsidebar-menu")
    //       .classList.remove("iconbar-second-close");
    // });

    const handleSearchKeyword = (keyword) => {
        keyword ? addFix() : removeFix();
        const items = [];
        if (keyword.length > 0) {
            setsearchValue(keyword);
            setspinner(true);
            setTimeout(function () {
                setspinner(false);
            }, 1000);
        } else {
            setspinner(false);
        }
        mainmenu.filter((menuItems) => {
            if (
                menuItems.title.toLowerCase().includes(keyword) &&
                menuItems.type === "link"
            ) {
                items.push(menuItems);
            }
            if (!menuItems.children) return false;
            menuItems.children.filter((subItems) => {
                if (
                    subItems.title.toLowerCase().includes(keyword) &&
                    subItems.type === "link"
                ) {
                    subItems.icon = menuItems.icon;
                    items.push(subItems);
                }
                if (!subItems.children) return false;
                subItems.children.filter((suSubItems) => {
                    if (suSubItems.title.toLowerCase().includes(keyword)) {
                        suSubItems.icon = menuItems.icon;
                        items.push(suSubItems);
                    }
                    return suSubItems;
                });
                return subItems;
            });
            checkSearchResultEmpty(items);
            setsearchValue(items);
            return menuItems;
        });
    };

    const addFix = () => {
        setSearchResult(true);
        document.querySelector(".Typeahead-menu").classList.add("is-open");
        document.body.classList.add("offcanvas");
    };

    const removeFix = () => {
        setSearchResult(false);
        setsearchValue("");
        document.querySelector(".Typeahead-menu").classList.remove("is-open");
        document.body.classList.remove("offcanvas");
    };

    const checkSearchResultEmpty = (items) => {
        if (!items.length) {
            setSearchResultEmpty(true);
            document.querySelector(".empty-menu").classList.add("is-open");
        } else {
            setSearchResultEmpty(false);
            document.querySelector(".empty-menu").classList.remove("is-open");
        }
    };

    const openCloseSidebar = (sidebartoggle) => {
        if (sidebartoggle === "iconsidebar-menu") {
            setSidebar("iconbar-mainmenu-close");
            document
                .querySelector(".iconsidebar-menu")
                .classList.add("iconbar-second-close");
            document
                .querySelector(".iconsidebar-menu")
                .classList.remove("iconbar-mainmenu-close");
        } else {
            setSidebar("iconsidebar-menu");
            document
                .querySelector(".iconsidebar-menu")
                .classList.remove("iconbar-second-close");
        }
    };

    const showRightSidebar = () => {
        if (rightSidebar) {
            setRightSidebar(!rightSidebar);
            document.querySelector(".right-sidebar").classList.add("show");
        } else {
            setRightSidebar(!rightSidebar);
            document.querySelector(".right-sidebar").classList.remove("show");
        }
    };

    //full screen function
    const goFull = () => {
        if (
            (document.fullScreenElement &&
                document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)
        ) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    };

    const Navmenuhideandshow = () => {
        if (navmenu) {
            setNavmenu(!navmenu);
            document.querySelector(".nav-menus").classList.add("open");
        } else {
            setNavmenu(!navmenu);
            document.querySelector(".nav-menus").classList.remove("open");
        }
    };


    const onNavClick = ()=>{
        if(navmenu){
            setCname("onhover-dropdown");
            setNavmenu(!navmenu);
        }else {
            setCname(null);
            setNavmenu(!navmenu);
        }
   

    };



    const openCloseSearch = () => {
        if (searchinput) {
            setSearchinput(!searchinput);
            document.querySelector(".Typeahead-input").classList.add("open");
        } else {
            setSearchinput(!searchinput);
            document.querySelector(".Typeahead-input").classList.remove("open");
        }
    };

    return (
        <div className="page-main-header">
            <div className="mobile-sidebar">
        <div
          className="media-body text-right switch-sm"
          style={{ marginLeft: 15, marginTop: 15 }}
        >
          <label className="switch ml-3" id="header">
            <AlignCenter
              className="font-primary"
              onClick={() => openCloseSidebar(sidebar)}
            />
          </label>
        </div>
      </div>
            <div className="main-header-right" style={{height:"60px"}}>
                <div className="main-header-left text-center">
                    <div className="logo-wrapper" style={{ marginLeft: -10 }}>
                        <Link to="/">
                        <img
                           src={require("../../../assets/images/logo/Vantr-v.png")}
                            alt=""
                            style={{ height:"40px", width:"45px"}}
                        />
                        </Link>
                    </div>
                </div>
                <div className="nav-right col pull-right right-menu">
                    <ul className="nav-menus">
                        {props.searchbar && (
                            <li>
                                <Form
                                    className="form-inline search-form"
                                    action="#"
                                    method="get"
                                >
                                    <FormGroup>
                                        <div className="Typeahead Typeahead--twitterUsers">
                                            <div className="u-posRelative">
                                                <input
                                                    className="Typeahead-input form-control-plaintext"
                                                    id="demo-input"
                                                    type="text"
                                                    placeholder="Search ...."
                                                    defaultValue={searchValue}
                                                    onChange={(e) =>
                                                        handleSearchKeyword(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <div
                                                    className={`spinner-border Typeahead-spinner ${spinner === true
                                                        ? "show"
                                                        : ""
                                                        }`}
                                                    role="status"
                                                >
                                                    <span className="sr-only">
                                                        Loading...
                                                    </span>
                                                </div>
                                                <span
                                                    className="d-sm-none mobile-search"
                                                    onClick={openCloseSearch}
                                                >
                                                    <Search />
                                                </span>
                                            </div>
                                            <div
                                                className="Typeahead-menu custom-scrollbar"
                                                id="search-outer"
                                            >
                                                {searchValue
                                                    ? searchValue.map(
                                                        (data, index) => {
                                                            return (
                                                                <div
                                                                    className="ProfileCard u-cf"
                                                                    key={
                                                                        index
                                                                    }
                                                                >
                                                                    <div className="ProfileCard-avatar">
                                                                        {
                                                                            data.icon
                                                                        }
                                                                    </div>
                                                                    <div className="ProfileCard-details">
                                                                        <div className="ProfileCard-realName">
                                                                            <Link
                                                                                to={
                                                                                    data.path
                                                                                }
                                                                                className="realname"
                                                                                onClick={
                                                                                    removeFix
                                                                                }
                                                                            >
                                                                                {
                                                                                    data.title
                                                                                }
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                    : ""}
                                            </div>
                                            <div className="Typeahead-menu empty-menu">
                                                <div className="tt-dataset tt-dataset-0">
                                                    <div className="EmptyMessage">
                                                        Opps!! There are no
                                                        result found.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Form>
                            </li>
                        )}
                        {/* <div style={{ alignItems: "end", justifyContent: "end" }}>
              <li>
                <a className="text-dark" href="#!" onClick={goFull}>
                  <Maximize />
                </a>
              </li>
            </div> */}
                        {/* <li className="onhover-dropdown">
              <img
                className="img-fluid img-shadow-warning"
                src={require("../../../assets/images/dashboard/notification.png")}
                alt=""
              />
              <ul className="onhover-show-div notification-dropdown">
                <li className="gradient-secondary">
                  <h5 className="f-w-700">Notifications</h5><span>You have 6 unread messages</span>
                </li>
                <li>
                  <div className="media">
                    <div className="notification-icons bg-success mr-3"><ThumbsUp className="mt-0" /></div>
                    <div className="media-body">
                      <h6>Someone Likes Your Posts</h6>
                      <p className="mb-0"> 2 Hours Ago</p>
                    </div>
                  </div>
                </li>
                <li className="pt-0">
                  <div className="media">
                    <div className="notification-icons bg-info mr-3" ><MessageCircle className="mt-0" /></div>
                    <div className="media-body">
                      <h6>3 New Comments</h6>
                      <p className="mb-0"> 1 Hours Ago</p>
                    </div>
                  </div>
                </li>
                <li className="bg-light txt-dark"><a href="#">All </a> notification</li>
              </ul>
            </li> */}
                        <li
                            className="onhover-dropdown"
                            style={{ marginRight: "30px" }}
                        >
                            {" "}
                            <span className="media user-header">
                                <img
                                    className="img-fluid"
                                    src={man}
                                    alt=""
                                    width={10}
                                    height={10}
                                />
                            </span>
                            {/* <li className="onhover-dropdown"><img className="img-fluid"   src={man} alt=""/> */}
                            <ul className="onhover-show-div profile-dropdown">
                            <li className="gradient-primary">
                                    <h5
                                        className="f-w-600 mb-0"
                                        style={{ color: "#ffffff" }}
                                    >
                                        Hello {props?.userName}
                                    </h5>
                                    <span></span>
                                </li>
                                {/* <li><FileText />Taskboard</li>
                <li><Settings />Settings</li> */}
                                {/* <li onClick={onMyWalletClick}>
                                    <CreditCard />
                                    My Wallet
                                </li>
                                <li onClick={onMyAssetClick}>
                                    <Folder />
                                    My Assets
                                </li> */}
                                <li onClick={onLogoutClick}>
                                    <LogOut />
                                    Logout
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div
                        className="d-lg-none mobile-toggle pull-right"
                        // onClick={Navmenuhideandshow}
                    >
                        {/* <MoreHorizontal /> */}
                        <li
                            // className="onhover-dropdown"
                            className={navmenu ? "onhover-dropdown" : null}
                            // style={{ marginRight: "30px" }}
                            // onClick={onNavClick}
                        >
                            {" "}
                            <span className="media user-header">
                            {/* <MoreHorizontal 
                             onClick={onNavClick}
                             /> */}
                              <img
                                    className="img-fluid"
                                    src={man}
                                    alt=""
                                    width={50}
                                    height={50}
                                    onClick={onNavClick}
                                />
                            </span>
                            {/* <li className="onhover-dropdown"><img className="img-fluid"   src={man} alt=""/> */}
                            <div  >

                          
                            <ul className="onhover-show-div profile-dropdown" style={{left:'-125px !important'}}>
                                <li className="gradient-primary">
                                    <h5
                                        className="f-w-600 mb-0"
                                        style={{ color: "#ffffff" }}
                                    >
                                        Hello {props?.userName}
                                    </h5>
                                    <span></span>
                                </li>
                                {/* <li><FileText />Taskboard</li>
                <li><Settings />Settings</li> */}
                                {/* <li onClick={onMyWalletClick}>
                                    <CreditCard />
                                    My Wallet
                                </li>
                                <li onClick={onMyAssetClick}>
                                    <Folder />
                                    My Assets
                                </li> */}
                                <li onClick={onLogoutClick}>
                                    <LogOut />
                                    Logout
                                </li>
                            </ul>
                            </div>
                        </li>
                    </div>
                </div>
                <script id="result-template" type="text/x-handlebars-template">
                    <div className="ProfileCard u-cf">
                        <div className="ProfileCard-avatar">
                            <i className="pe-7s-home"></i>
                        </div>
                        <div className="ProfileCard-details">
                            <div className="ProfileCard-realName"></div>
                        </div>
                    </div>
                </script>
                <script id="empty-template" type="text/x-handlebars-template">
                    <div className="EmptyMessage">
                        Your search turned up 0 results.
                    </div>
                </script>
            </div>
        </div>
    );
};

export default HeaderSM;
